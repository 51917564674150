import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const verifyRecaptcha = createAsyncThunk(
  "recaptcha/verify",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/verify-recaptcha", { token });
      if (response.data.success) {
        return { success: true, score: response.data.score };
      } else {
        return rejectWithValue({ success: false, message: response.data.message });
      }
    } catch (error) {
      return rejectWithValue({ success: false, message: "Server error" });
    }
  }
);

const recaptchaSlice = createSlice({
  name: "recaptcha",
  initialState: {
    verified: false,
    score: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyRecaptcha.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyRecaptcha.fulfilled, (state, action) => {
        state.loading = false;
        state.verified = true;
        state.score = action.payload.score;
      })
      .addCase(verifyRecaptcha.rejected, (state, action) => {
        state.loading = false;
        state.verified = false;
        state.error = action.payload?.message || "Verification failed";
      });
  },
});

export default recaptchaSlice.reducer;
